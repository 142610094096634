import * as React from 'react'
import { Trans } from 'react-i18next'
import { Card, Spinner } from 'react-bootstrap'
import { FileUploader } from 'react-drag-drop-files'

import styles from 'components/system_wide/file_dropzone.module.css'

class FileDropzone extends React.Component {
  static defaultProps = {
    multiple: false,
    dimmed: false,
    title: <Trans i18nKey="system_wide.file_dropzone.title">Seleziona il file da importare</Trans>,
    progressingTitle: <Trans i18nKey="system_wide.file_dropzone.progressing">Operazione in corso...</Trans>,
    description: (
      <Trans
        i18nKey="system_wide.file_dropzone.description"
        defaults="Trascina il file in questa area <1></1> o clicca qui per sceglierne uno."
        components={{ 1: <br /> }}
      />
    ),
    accept: ['xlsx', 'xls'],
    onDrop: () => {},
  }

  render() {
    let {
      dimmed,
      uploaded,
      title,
      progressingTitle,
      description,
      accept,
      onDrop,
      tableColumn = false,
      errored = false,
    } = this.props
    let types = accept.length ? { types: accept } : {}

    if (tableColumn) {
      if (dimmed) {
        return (
          <Card className={styles.card_progressing}>
            <div className="p-1 text-center w-100">
              <div className={`rounded p-2 ${styles.bg_gray}`}>Caricamento...</div>
            </div>
          </Card>
        )
      } else if (uploaded) {
        return (
          <Card className={styles.card_progressing}>
            <div className="p-1 text-center w-100">
              <div className={`d-flex rounded p-2 ${styles.bg_green}`}>
                <i className="fa fa-check pe-2" />
                Caricato
              </div>
            </div>
          </Card>
        )
      } else if (errored) {
        return (
          <Card className={styles.card_progressing}>
            <div className="p-1 text-center w-100">
              <div className={`d-flex rounded p-2 ${styles.bg_error}`}>
                <i className="fa fa-close pe-2" />
                Errore
              </div>
            </div>
          </Card>
        )
      } else {
        return (
          <FileUploader
            classes={styles.custom_dropzone}
            name="file-uploader"
            multiple={false}
            handleChange={onDrop}
            {...types}>
            <Card className={styles.card_progressing}>
              <div className="text-center w-100">
                <div className={`rounded ${styles.bg_gray}`}>{title}</div>
              </div>
            </Card>
          </FileUploader>
        )
      }
    }

    // Dropzone standard
    if (dimmed) {
      return (
        <Card className={styles.card_progressing}>
          <div className="p-2 text-center w-100">
            <div className={`rounded p-2 ${styles.bg_gray}`}>
              <h4>{progressingTitle}</h4>
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Caricamento...</span>
              </Spinner>
            </div>
          </div>
        </Card>
      )
    } else {
      return (
        <FileUploader
          classes={styles.custom_dropzone}
          name="file-uploader"
          multiple={false}
          handleChange={onDrop}
          {...types}>
          <Card className={styles.card_progressing}>
            <div className="p-2 text-center w-100">
              <div className={`rounded p-2 ${styles.bg_gray}`}>
                {title !== '' && (
                  <>
                    <div className="pb-1">{title}</div>
                    <br />
                  </>
                )}
                {description !== '' && <small>{description}</small>}
              </div>
            </div>
          </Card>
        </FileUploader>
      )
    }
  }
}

export default FileDropzone
