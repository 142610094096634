import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'lib/axios'
import i18next from 'i18next'

import config from 'config'
import { error, success } from 'components/system_wide/notification'
import { setLoading, resetCurrentAssignment, setCurrentAssignment } from 'store/assignments'
import { redirectLocation } from 'store/common'

const { t } = i18next

export const initialState = {
  services: [],
  payment_methods: [],
  errors: [],
  loading: false,
  show_add_services: false,
  show_signature: false,
  otp_verification_id: '',
  verificable_id: '',
  current_service: { prices: [], service_options: [], suppliers: [] },
  edit_service: {},
}

export const getServices = createAsyncThunk('assignments/getServices', async ({ module = '' }, thunkApi) => {
  try {
    let url = `${config.SERVER_API_URL}/v1/services`
    if (module !== '') {
      url = url + `?filter[with_associable_type]=${module}`
    }
    let response = await axios({
      url,
      method: 'GET',
    })
    let included = response.data.included
    let services = response.data.data.map(u => {
      let suppliers = u.relationships.suppliers.data.map(su => {
        let supplier = included.find(suu => suu.type === 'suppliers' && suu.id === su.id)
        let { name } = supplier.attributes
        return { value: supplier.id, label: name }
      })
      let { description, position, icon_classes, selectable_price, codename } = u.attributes
      let service_options = u.relationships.service_options.data.map(so => {
        let service_option = included.find(sx => sx.type === 'service_options' && sx.id === so.id)
        let { codename, exclusive } = service_option.attributes
        return { value: service_option.id, label: codename, exclusive }
      })
      return {
        value: u.id,
        codename,
        label: `${description}`,
        position,
        icon_classes,
        selectable_price,
        service_options,
        suppliers,
      }
    })
    return { services }
  } catch (err) {
    if (err?.message === 'canceled') return thunkApi.rejectWithValue()
    error(t('store.assignments.get_services_error', 'Impossibile recuperare i servizi'))
    return thunkApi.rejectWithValue()
  }
})

export const getDossier = createAsyncThunk('assignments/getService', async (dossier_id, thunkApi) => {
  try {
    let response = await axios({
      url: `${config.SERVER_API_URL}/v1/dossiers/${dossier_id}`,
      method: 'GET',
    })
    let { attributes, id } = response.data.data
    let { quantity, price, real_estate_units, fee, service, service_options, supplier, payment_method } = attributes
    return {
      id,
      quantity,
      price,
      service_options,
      supplier: { value: supplier.id, label: supplier.name },
      real_estate_units,
      fee,
      service_id: service.id,
      selected_payment: payment_method,
      selected_service: { ...service, value: service.id, label: service.description },
    }
  } catch (err) {
    console.log(err)
    error(t('store.assignments.get_service_error', 'Impossibile recuperare il servizio'))
    return thunkApi.rejectWithValue()
  }
})

export const getServicePrices = createAsyncThunk(
  'assignments/getServicePrices',
  async ({ service_id, service_option_id, supplier_id }, thunkApi) => {
    try {
      let filter_service_option = service_option_id ? `&filter[with_service_options]=${service_option_id}` : ''
      let filter_supplier = supplier_id ? `&filter[with_suppliers]=${supplier_id}` : ''
      let response = await axios({
        url: `${config.SERVER_API_URL}/v1/services/${service_id}/prices?${filter_service_option}${filter_supplier}`,
        method: 'GET',
      })
      let prices = response.data.data.map(u => {
        let { amount, typology } = u.attributes
        return { id: u.id, amount, typology }
      })
      let service_options =
        thunkApi.getState().assignments.services.services.find(s => s.value === service_id).service_options || []
      return { prices, service_options }
    } catch (err) {
      if (err?.message === 'canceled') return thunkApi.rejectWithValue()
      error(t('store.assignments.get_service_prices_error', 'Impossibile recuperare i prezzi del servizio'))
      return thunkApi.rejectWithValue()
    }
  }
)

export const getPaymentMethods = createAsyncThunk('assignments/getPaymentMethods', async (_, thunkApi) => {
  try {
    let response = await axios({
      url: `${config.SERVER_API_URL}/v1/payment_methods`,
      method: 'GET',
    })
    let payment_methods = response.data.data.map(u => {
      let { name } = u.attributes
      return { value: u.id, label: `${name}` }
    })
    return { payment_methods }
  } catch (err) {
    if (err?.message === 'canceled') return thunkApi.rejectWithValue()
    error(t('store.assignments.get_payment_methods_error', 'Impossibile recuperare i metodi di pagamento'))
    return thunkApi.rejectWithValue()
  }
})

export const createService = createAsyncThunk(
  'assignments/createService',
  async ({ assignment_id, service }, thunkApi) => {
    try {
      let { service_option, supplier, ...service_data } = service
      service_data.supplier_id = supplier.value
      service_data.service_options = [service_option.value]
      await axios({
        url: `${config.SERVER_API_URL}/v1/assignments/${assignment_id}/dossiers`,
        method: 'POST',
        data: { ...service_data },
      })
      thunkApi.dispatch(setLoading(true))
      thunkApi.dispatch(resetCurrentAssignment())
      thunkApi.dispatch(setCurrentAssignment(assignment_id))
      thunkApi.dispatch(toggleShowAddServices())
    } catch (err) {
      console.log(err)
      let {
        data: { errors: from_server },
      } = err.response
      let errors = { from_server: Array.isArray(from_server) ? from_server : [from_server] }
      thunkApi.dispatch(updateErrors({ errors }))
      error(t('store.users.create_assignment_service_error', 'Impossibile aggiungere il servizio'))
      return thunkApi.rejectWithValue()
    }
  }
)

export const editService = createAsyncThunk('assignments/editService', async ({ assignment_id, service }, thunkApi) => {
  try {
    await axios({
      url: `${config.SERVER_API_URL}/v1/assignments/${assignment_id}/dossiers/${service.id}`,
      method: 'PATCH',
      data: { ...service },
    })
    thunkApi.dispatch(setLoading(true))
    thunkApi.dispatch(resetCurrentAssignment())
    thunkApi.dispatch(setCurrentAssignment(assignment_id))
    thunkApi.dispatch(toggleShowAddServices())
  } catch (err) {
    console.log(err)
    let {
      data: { errors: from_server },
    } = err.response
    let errors = { from_server: Array.isArray(from_server) ? from_server : [from_server] }
    thunkApi.dispatch(updateErrors({ errors }))
    error(t('store.users.edit_assignment_service_error', 'Impossibile modificare il servizio'))
    return thunkApi.rejectWithValue()
  }
})

export const deleteService = createAsyncThunk(
  'assignments/deleteService',
  async ({ assignment_id, service_id }, thunkApi) => {
    try {
      await axios({
        url: `${config.SERVER_API_URL}/v1/assignments/${assignment_id}/dossiers/${service_id}`,
        method: 'DELETE',
      })
      thunkApi.dispatch(setLoading(true))
      thunkApi.dispatch(resetCurrentAssignment())
      thunkApi.dispatch(setCurrentAssignment(assignment_id))
      success(t('store.assignments.delete_service_success', 'Servizio eliminato con successo'))
    } catch (err) {
      console.log(err)
      error(t('store.users.create_assignment_service_error', 'Impossibile eliminare il servizio'))
      return thunkApi.rejectWithValue()
    }
  }
)

export const confirmOTP = createAsyncThunk(
  'assignments/confirmOTP',
  async ({ assignment_id, otp_verification_id, verificable_id, otp }, thunkApi) => {
    try {
      await axios({
        url: `${config.SERVER_API_URL}/v1/verificable/${verificable_id}/otp_verifications/${otp_verification_id}`,
        method: 'PUT',
        data: { otp },
      })
      await axios({
        url: `${config.SERVER_API_URL}/v1/assignments/${assignment_id}`,
        method: 'PUT',
      })
      thunkApi.dispatch(setCurrentAssignment(assignment_id))
      success(t('store.assignments.sign_assignment_confirm_success', 'OTP accettato, ora è possibile firmare!'))
    } catch (err) {
      console.log(err)
      error(t('store.assignments.confirm_otp_assignment_error', 'OTP errato'))
      return thunkApi.rejectWithValue()
    }
  }
)

export const renewOTP = createAsyncThunk(
  'assignments/renewOTP',
  async ({ assignment_id, otp_verification_id, verificable_id, otp }, thunkApi) => {
    try {
      await axios({
        url: `${config.SERVER_API_URL}/v1/verificable/${verificable_id}/otp_verifications/${otp_verification_id}/renew`,
        method: 'POST',
      })
      thunkApi.dispatch(setCurrentAssignment(assignment_id))
      success(t('store.assignments.sign_assignment_renew_success', 'OTP richiesto con successo'))
    } catch (err) {
      console.log(err)
      error(t('store.assignments.confirm_otp_assignment_error', 'OTP errato'))
      return thunkApi.rejectWithValue()
    }
  }
)

export const signAssignment = createAsyncThunk(
  'assignments/signAssignment',
  async ({ assignment_id, signature }, thunkApi) => {
    try {
      let response = await axios({
        url: `${config.SERVER_API_URL}/v1/assignments/${assignment_id}`,
        method: 'GET',
      })
      let order_id = response.data.data.attributes.order.id

      let formData = new FormData()
      const res = await fetch(signature)
      const blob = await res.blob()
      const file = new File([blob], 'signature.png', { type: 'image/png' })
      formData.append('_jsonapi[sign]', file)
      formData.append('accepted', true)

      await axios({
        url: `${config.SERVER_API_URL}/v1/assignments/${assignment_id}/orders/${order_id}`,
        method: 'PUT',
        data: formData,
      })
      thunkApi.dispatch(setLoading(true))
      thunkApi.dispatch(resetCurrentAssignment())
      thunkApi.dispatch(setCurrentAssignment(assignment_id))
      thunkApi.dispatch(toggleShowSignature())
      thunkApi.dispatch(redirectLocation(`/assignments/${assignment_id}/affiliates`))
      success(t('store.assignments.sign_assignment_success', 'Firma inviata con successo'))
    } catch (err) {
      console.log(err)
      error(t('store.assignments.sing_assignment_error', 'Impossibile inviare la firma'))
      return thunkApi.rejectWithValue()
    }
  }
)

export const servicesSlice = createSlice({
  name: 'assignments/services',
  initialState,

  reducers: {
    toggleShowAddServices: state => {
      state.show_add_services = !state.show_add_services
      state.errors = []
      if (state.show_add_services === false) state.edit_service = {}
    },
    toggleShowSignature: state => {
      state.show_signature = !state.show_signature
    },
    updateErrors: (state, action) => {
      state.errors = action.payload.errors
    },
  },
  extraReducers: {
    [getServices.fulfilled]: (state, action) => {
      if (!action?.payload) return null
      let { services } = action.payload
      state.services = services
    },
    [getServices.rejected]: state => {
      state.services = initialState.services
    },
    [getServicePrices.fulfilled]: (state, action) => {
      if (!action?.payload) return null
      let { prices, service_options, suppliers } = action.payload
      state.current_service = { prices, service_options, suppliers }
    },
    [getServicePrices.rejected]: state => {
      state.current_service = initialState.current_service
    },
    [getDossier.fulfilled]: (state, action) => {
      if (!action?.payload) return null
      state.edit_service = { ...action.payload }
    },
    [getDossier.rejected]: state => {
      state.edit_service = {}
    },
    [getPaymentMethods.fulfilled]: (state, action) => {
      if (!action?.payload) return null
      let { payment_methods } = action.payload
      state.payment_methods = payment_methods
    },
    [getPaymentMethods.rejected]: state => {
      state.payment_methods = initialState.payment_methods
    },
  },
})

export const { toggleShowSignature, toggleShowAddServices, updateErrors } = servicesSlice.actions
export default servicesSlice.reducer
