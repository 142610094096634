import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'lib/axios'
import i18next from 'i18next'

import config from 'config'
import { success, error } from 'components/system_wide/notification'

const { t } = i18next

export const initialState = {
  files: [],
}

export const getDocuments = createAsyncThunk('documents/list', async (_, thunkApi) => {
  try {
    let url = `${config.SERVER_API_URL}/v1/attachments`
    let response = await axios({
      url,
      method: 'GET',
    })

    let { data } = response.data
    let attachments = data.map(d => {
      let { id, attributes } = d
      return { id, ...attributes }
    })
    return attachments
  } catch (err) {
    console.log(err)
    error(t('store.contracts.get_attachments_error', 'Impossibile recuperare i documenti'))
    return thunkApi.rejectWithValue()
  }
})

export const deleteDocument = createAsyncThunk('documents/delete', async (id, thunkApi) => {
  try {
    let url = `${config.SERVER_API_URL}/v1/attachments/${id}`
    await axios({
      url,
      method: 'DELETE',
    })
    success(t('store.contracts.delete_contract_success', 'Documento eliminato con successo'))
  } catch (err) {
    console.log(err)
    error(t('store.contracts.get_contract_error', 'Impossibile eliminare il documento'))
    return thunkApi.rejectWithValue()
  }
})

export const contractsSlice = createSlice({
  name: 'documents',
  initialState,

  reducers: {},
  extraReducers: {
    [getDocuments.fulfilled]: (state, action) => {
      state.files = action.payload
    },
    [getDocuments.rejected]: state => {
      state.files = []
    },
  },
})

// export const {} = contractsSlice.actions
export default contractsSlice.reducer
