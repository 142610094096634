import React from 'react'
import { connect } from 'react-redux'
import { Col, Form, Row, Spinner } from 'react-bootstrap'
import i18next from 'i18next'

import StyledSelect from './styled_select'
import { getCities, getProvinces, getRegions } from 'store/common'
import styles from './select_municipality.module.css'

const { t } = i18next

const capRegExp = /^[0-9]*$/

const mstp = state => {
  let { cities, regions, provinces } = state.common
  return {
    regions,
    provinces,
    cities,
  }
}

class SelectMunicipality extends React.Component {
  static defaultProps = {
    errors: [],
    region: '',
    province: '',
    city: '',
    codiceIstat: null,
    cap: '',
    labels: {},
    showRequiredMarks: false,
    hasCap: true,
    isReadOnly: false,
    onMunicipalityChange: () => {},
    disabled: false,
  }

  state = {
    labels: {
      region: t('common.form.region', 'Regione'),
      province: t('common.form.province', 'Provincia'),
      city: t('common.form.city', 'Città'),
      cap: t('common.form.cap', 'CAP'),
    },
  }

  async componentDidMount() {
    await this.props.dispatch(getRegions())
    let { region, province } = this.props
    if (region !== '') await this.props.dispatch(getProvinces(region))
    if (province !== '') await this.props.dispatch(getCities({ region, province }))
  }

  handleSelectChange = type => async option => {
    let { region, province } = this.props
    let value = option ? option.value ?? '' : ''
    let newValues = {}
    switch (type) {
      case 'region': {
        if (value !== '') await this.props.dispatch(getProvinces(value))
        newValues = {
          region: value,
          province: '',
          city: '',
        }
        break
      }
      case 'province': {
        if (value !== '') await this.props.dispatch(getCities({ region, province: value }))
        newValues = { province: value, city: '' }
        break
      }
      case 'city':
        newValues = { city: value }
        break
      default:
        break
    }
    this.setState(newValues)
    this.props.onChange({
      region: newValues.region ?? region,
      province: newValues.province ?? province,
      city_id: newValues.city,
    })
  }

  handleCapChange = ({ target }) => {
    if (!capRegExp.test(target.value) || target.value.length > 5) {
      return
    } else {
      this.props.onChange({ cap: target.value })
    }
  }

  render() {
    let { labels } = this.state
    let { region, province, city, cap, errors, cities, regions, provinces } = this.props
    let { showRequiredMarks, isReadOnly } = this.props
    let provDisabled = region === '' || isReadOnly === true
    let locDisabled = province === '' || isReadOnly === true

    if (Object.keys(regions).length === 0)
      return (
        <Row className="mb-3 mt-3 justify-content-center">
          <Spinner animation="border" variant="primary" />
        </Row>
      )

    return (
      <>
        <Row className="mb-3 pe-0">
          <Form.Group as={Col} className="mb-3 mb-md-0 col-3 col-md-3">
            <Form.Label>{labels.region}</Form.Label>
            {showRequiredMarks === true && <span> *</span>}
            <StyledSelect
              className={`${'region' in errors && 'form-control is-invalid'} ${
                isReadOnly === true ? styles.read_only : ''
              }`}
              isReadOnly={isReadOnly}
              isClearable
              disabled={isReadOnly === true}
              placeholder={isReadOnly === false ? labels.region : ''}
              value={regions.find(o => parseInt(o.value) === parseInt(region))}
              onChange={this.handleSelectChange('region')}
              options={regions}
            />
          </Form.Group>
          <Form.Control.Feedback type="invalid">{errors.region}</Form.Control.Feedback>
          <Form.Group as={Col} className="col-3 col-md-3">
            <Form.Label>{labels.province}</Form.Label>
            {showRequiredMarks === true && <span> *</span>}
            <StyledSelect
              className={`${'province' in errors && 'form-control is-invalid'} ${
                isReadOnly === true ? styles.read_only : ''
              }`}
              isClearable
              isReadOnly={isReadOnly}
              isDisabled={provDisabled}
              placeholder={isReadOnly === false ? labels.province : ''}
              value={provinces.find(o => parseInt(o.value) === parseInt(province))}
              onChange={this.handleSelectChange('province')}
              options={provinces}
            />
          </Form.Group>
          <Form.Control.Feedback type="invalid">{errors.province}</Form.Control.Feedback>
          <Form.Group as={Col} className="col-4 col-md-4">
            <Form.Label>{labels.city}</Form.Label>
            {showRequiredMarks === true && <span> *</span>}
            <StyledSelect
              className={`${('city' in errors || 'city' in errors) && 'form-control is-invalid'} ${
                isReadOnly === true ? styles.read_only : ''
              }`}
              isClearable
              isReadOnly={isReadOnly}
              placeholder={isReadOnly === false ? labels.city : ''}
              isDisabled={locDisabled}
              value={cities.find(o => parseInt(o.value) === parseInt(city))}
              onChange={this.handleSelectChange('city')}
              options={cities}
            />
          </Form.Group>
          <Form.Control.Feedback type="invalid">{errors.city}</Form.Control.Feedback>
          <Form.Group as={Col} className="col-2 col-md-2 pe-0">
            <Form.Label>{labels.cap}</Form.Label>
            {showRequiredMarks === true && <span> *</span>}
            <Form.Control
              className={`${'cap' in errors && 'form-control is-invalid'} ${
                isReadOnly === true ? styles.read_only : ''
              }`}
              name="cap"
              disabled={isReadOnly === true}
              placeholder={isReadOnly === false ? labels.cap : ''}
              value={cap}
              onChange={this.handleCapChange}
              style={{ minHeight: '2.25rem' }}
            />
          </Form.Group>
        </Row>
      </>
    )
  }
}

export default connect(mstp)(SelectMunicipality)
