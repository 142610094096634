import axios from 'axios'
import cookies from 'browser-cookies'
import store, { resetStore } from 'store'

import Notification from 'components/system_wide/notification'

const handleUnauthorized = () => {
  Notification.error('Session expired')
  cookies.erase('conhive-auth')
  store.dispatch(resetStore)
}

const instance = axios.create()

let reqs = []

instance.interceptors.request.use(
  config => {
    const controller = new AbortController()
    const token = JSON.parse(cookies.get('conhive-auth'))
    if (token) config.headers['Authorization'] = token
    if (!/login/.test(config.url)) {
      config.headers['Accept'] = 'application/vnd.api+json'
      config.headers['Content-Type'] = 'application/vnd.api+json'
    }
    if (reqs.find(r => r === `${config.method}|${config.url}`)) {
      setTimeout(() => {
        reqs = reqs.filter(r => r !== `${config.method}|${config.url}`)
      }, 5000)
      controller.abort()
    }
    reqs.push(`${config.method}|${config.url}`)
    return {
      ...config,
      signal: controller.signal,
    }
  },
  error => {
    return Promise.reject(error)
  }
)

instance.interceptors.response.use(
  res => {
    if (!/login/.test(res.config.url) && res.status === 401) {
      handleUnauthorized()
    }
    if (res.headers.authorization) cookies.set('conhive-auth', JSON.stringify(res.headers.authorization), { path: '/' })
    reqs = reqs.filter(r => r !== `${res.config.method}|${res.config.url}`)
    return res
  },
  err => {
    let { config, status } = err?.response ?? { config: {}, status: 0 }
    reqs = reqs.filter(r => r !== `${config?.method}|${config?.url}`)
    if (status === 401) {
      if (!/login/.test(config.url)) handleUnauthorized()
      return Promise.reject(err)
    }
    return Promise.reject(err)
  }
)

export default instance
