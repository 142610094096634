import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'lib/axios'
import config from 'config'

export const initialState = { strings: {} }

export const getTranslations = createAsyncThunk('translations/get', async (_, thunkApi) => {
  try {
    let state = thunkApi.getState().translations
    if (Object.keys(state.strings).length === 0) {
      let response = await axios({
        url: `${config.SERVER_API_URL}/v1/translations?locale=it`,
        method: 'GET',
      })
      return response.data.data
    } else {
      return state.translations.strings
    }
  } catch (err) {
    return thunkApi.rejectWithValue()
  }
})

export const translationsSlice = createSlice({
  name: 'translations',
  initialState,

  reducers: {},
  extraReducers: {
    [getTranslations.fulfilled]: (state, action) => {
      state.strings = action.payload
    },
    [getTranslations.rejected]: state => {
      state = { strings: {} }
    },
  },
})

// export const {} = translationsSlice.actions
export default translationsSlice.reducer
